/* Google Maps */
#map_canvas {
	height: 100%;
}

@media print {
  html, body {
    height: auto;
  }

  #map_canvas {
    height: 650px;
  }
}

.mapInfoWindowHeading {
	font-size: large;
}

/* End Google map stuff */

#profile {
    background: #aaaaaa url('../images/logo.png') no-repeat;
    text-align: right;
	vertical-align: bottom;
	height: 55px;
	color: white;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 5px;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
}

#profile a {color: #ffffff; text-decoration: underline; }
#profile a:link {color: #ffffff; }
#profile a:visited {color: #ffffff;}
#profile a:hover {color: #cccccc;}

img {
	border-style: none;
}

fieldset {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
    border: 1px solid #CCC;
    display: inline-block;
	padding: 10px;
}

.updateStatus {
	background: url('../images/status-grey.png') no-repeat 0 4px;
	padding-left: 20px !important;
}

.liStatus {
    background: url('../images/status-grey.png') no-repeat 0 0;
    padding-left: 20px !important;
    padding-bottom: 5px;
}

.statusError { background-image: url('../images/error.png'); }
.statusOk { background-image: url('../images/tick-circle-frame.png'); }
.statusWarning { background-image: url('../images/exclamation.png'); }
.statusDisabled { background-image: url('../images/status-grey.png'); }

.fileType {
    background: url('../images/file-document.png') no-repeat 2px center;
    padding-left: 20px !important;
	cursor: pointer;
}

.listNoBullets {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.audio_ogg { background-image: url('../images/file-audio.png'); }
.audio_mp3 { background-image: url('../images/file-audio.png'); }
.audio_wav { background-image: url('../images/file-audio.png'); }
.application_x-compressed { background-image: url('../images/file-compressed.png'); }
.application_vnd.ms-excel { background-image: url('../images/file-excel.png'); }
.application_msword { background-image: url('../images/file-word.png'); }
.application_pdf { background-image: url('../images/file-pdf.png'); }
.text_plain { background-image: url('../images/file-text.png'); }
.video_ogg { background-image: url('../images/file-video.png'); }
.video_mpeg { background-image: url('../images/file-video.png'); }
.video_mp4 { background-image: url('../images/file-video.png'); }
.video_avi { background-image: url('../images/file-video.png'); }
.video_x-ms-wmv { background-image: url('../images/file-video.png'); }
.image_jpeg { background-image: url('../images/file-image.png'); }
.image_png { background-image: url('../images/file-image.png'); }
.image_bmp { background-image: url('../images/file-image.png'); }
.image_gif { background-image: url('../images/file-image.png'); }
.image_tiff { background-image: url('../images/file-image.png'); }

.general_video { background-image: url('../images/file-video.png'); }
.general_image { background-image: url('../images/file-image.png'); }

#tagList {
	list-style-image: url("../images/tag.png");
	cursor: pointer;
}

.tag-color-0 {	list-style-image: url("../images/tag-color-0.png"); }
.tag-color-1 {	list-style-image: url("../images/tag-color-1.png"); }
.tag-color-2 {	list-style-image: url("../images/tag-color-2.png"); }
.tag-color-3 {	list-style-image: url("../images/tag-color-3.png"); }
.tag-color-4 {	list-style-image: url("../images/tag-color-4.png"); }
.tag-color-5 {	list-style-image: url("../images/tag-color-5.png"); }
.tag-color-6 {	list-style-image: url("../images/tag-color-6.png"); }
.tag-color-7 {	list-style-image: url("../images/tag-color-7.png"); }

.tag {
	cursor: pointer;
}

.trash {
    background: url('../images/trash.png') no-repeat;
    float:right;
	width: 26px;
	height: 26px;
	padding-bottom: 3px;
}

.trashHover {
	background-image: url('../images/trash_opened.png') !important;
}

.activeTrash {
	border: 1px solid #c5dbec;
	background-color: #dfeffc;
	font-weight: bold;
	color: #2e6e9e;
	width: 24px;
	height: 24px;
}

#toggleTagToolbox {
    background: url('../images/tag.png') no-repeat;
    padding-left: 20px !important;
	padding-bottom: 5px !important;
	cursor: pointer;
}

#tagToolbox {
	overflow:auto;
	width: 300px;
	max-height: 110px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
    border: 1px solid #dddddd;
    display: none;
/* float: right; */
}

#tagToolbox::-webkit-scrollbar {
    width: 12px;
}

#tagToolbox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 12px;
}

#tagToolbox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.toolColumn {
	text-align: right;
}

#file_container {
	.filePreview {
		width: auto;
		max-height: 165px;
		margin-top: 10px;
	}
	.fileButton {
		display: inline
	}
	input[type='file'] {
		color: transparent;
		margin: 0px !important;
		padding: 0px !important;
		width: 75px;
	}
}

.sideBySide {
	float: left;
	margin-right: 10px
}

#companyAssignDropDown {
	width:250px;
}

.adminUnitsFilterFieldset {
	width: 400px;
}

.adminUnitsFilterFieldset {
	width: 400px;
}

#filter {
	/* display: none; */
}

#toogleToolboxIcon {
	cursor: pointer;
	display: none;
}

#tagFilter {
	position: relative;
	overflow:auto;
	width: 300px;
	/*max-height: 110px;*/
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
    border: 1px solid #dddddd;
}

#tagFilter::-webkit-scrollbar {
    width: 12px;
}

#tagFilter::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 12px;
}

#tagFilter::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

#tagTreeCheckBox {
	border: none;
}

/* Fixes indent of multiselects */
dl, dt, dd {
	margin:0;
}

input, select {
	margin-bottom: 10px;
}

#filterText {
	margin-top: 5px;
}

.link {
	cursor: pointer;
}

.textlink {
	text-decoration: underline;
	cursor: pointer;
}

#mapDialogContainer {
	width: 400px;
	height: 250px;
}

#dialog label, #dialog input {
	display:block;
}

#dialog label {
	margin-top: 0.5em;
}

#dialog input, #dialog textarea {
	width: 250px;
}

.errorHighlight {
	background-color: #ffd0d0;
}

.warningMessage {
    background: url('../images/exclamation.png') no-repeat left center;
    padding-left: 20px;
}

#tabs {
	margin-top: 1em;
}

#tabs li .ui-icon-close {
	float: left;
	margin: 0.4em 0.2em 0 0;
	cursor: pointer;
}

#add_tab {
	cursor: pointer;
}

#guestCompaniesBox {
	overflow:auto;
	width: 300px;
	max-height: 110px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
    border: 1px solid #dddddd;
}

#guestCompaniesHeader {
	width: 300px;
	height: 18px;
}

#guestCompaniesClearButton {
	float: right;
	cursor: pointer;
}

#treeUserPermissions {
	overflow:auto;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
	margin-top: 2px;
}

#vehicleTags {
	overflow:auto;
	width: 400px;
	max-height: 240px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
    border: 1px solid #dddddd;
}

#rawXmlDialog {
	display: none;
}

.ui-dialog {
	position: fixed;
}

/** React Gauge Component **/
.gauge-el {
}
	.gauge-el svg {
		font: 10px sans-serif;
		/*margin-left: 8px;*/
	}

	.gauge-el .needle,
	.gauge-el .needle-center {
		fill: #464A4F;
	}

/* Fix to support modal from es6 codebase */

.ReactModal__Overlay {
	-webkit-perspective: 600;
	perspective: 600;
	opacity: 0;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open {
	opacity: 1;
	transition: opacity 150ms ease-out;
}

.ReactModal__Content {
	-webkit-transform: scale(0.5) rotateX(-30deg);
	transform: scale(0.5) rotateX(-30deg);

	.modal-content {
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.3rem;
		display: flex;
		flex-direction: column;
		outline: 0;
		position: relative;

		.modal-header {
			align-items: center;
			border-bottom: 1px solid #e9ecef;
			display: flex;
			justify-content: space-between;
			padding: 15px;

			.modal-title {
				font-family: inherit;
				font-size: 1.5rem;
				font-weight: 500;
				line-height: 1.5;
				margin-bottom: 0;
				margin-top: 0;
			}

			.close {
				-webkit-appearance: none;
				background: transparent;
				border: 0;
				color: #000;
				float: right;
				font-size: 1.5rem;
				font-weight: bold;
				line-height: 1;
				opacity: .5;
				padding: 0;
				text-shadow: 0 1px 0 #fff;
			}
		}

		.modal-body {
			color: #212529;
			flex: 1 1 auto;
			font-family: sans-serif;
			font-size: 9pt;
			font-weight: normal;
			line-height: 1.5;
			padding: 15px;
			position: relative;
			text-align: left;

			a {
				background-color: transparent;
				color: #007bff;
				text-decoration: none;
			}
		}

		.modal-footer {
			align-items: center;
			border-top: 1px solid #e9ecef;
			display: flex;
			justify-content: space-between;
			padding: 15px;
		}
	}
}

.ReactModal__Content--after-open {
	-webkit-transform: scale(1) rotateX(0deg);
	transform: scale(1) rotateX(0deg);
	transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.ReactModal__Content--before-close {
	-webkit-transform: scale(0.5) rotateX(30deg);
	transform: scale(0.5) rotateX(30deg);
	transition: all 150ms ease-in;
}

.ReactModal__Content.modal-dialog {
	border: none;
	background-color: transparent;
}